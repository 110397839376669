import request from '@/utils/request'

/**
 * 获取房源列表
 */
export const getHouseList = (data) => {
  return request({
    url: '/manageHouse/ownHouseList',
    method: 'POST',
    data
  })
}

/**
 * 房源上架
 */
export const houseUpShelves = (data) => {
  return request({
    url: '/manageHouse/houseUpShelves',
    method: 'POST',
    data
  })
}

/**
 * 房源下架
 */
export const houseOffShelves = (data) => {
  return request({
    url: '/manageHouse/houseOffShelves',
    method: 'POST',
    data
  })
}

/**
 * 企业委托、代理
 */
export const savePersonAgentHouse = (data) => {
  return request({
    url: '/manageEnterprise/SavePersonAgentHouse',
    method: 'POST',
    data
  })
}
/**
 * 企业委托、代理
 */
export const delOwnerHouse = (data) => {
  return request({
    url: '/manageHouse/del',
    method: 'POST',
    data
  })
}

/**
 * 房源二维码房源管理
 */
export const getOwnHouse = (data) => {
  return request({
    url: '/release/getOwnHouse',
    params: data
  })
}

/**
 * 获取企业列表
 */
export const getEnterpriseInfo = (data) => {
  return request({
    url: '/manageEnterprise/GetEnterpriseInfo',
    params: data
  })
}
/**
 * 获取放缓信息
 */
export const getHouseById = (data) => {
  return request({
    url: '/manageHouse/GetHouseById',
    params: data
  })
}

/**
 * 关闭核验码
 */
export const closeCheckCode = (data) => {
  return request({
    url: '/manageHouse/CloseCheckCode',
    method: 'POST',
    data
  })
}

/**
 * 房源拆分接口
 */
export const getSubleaseHouse = (data) => {
  return request({
    url: '/sulease/GetSubleaseHouse',
    method: 'POST',
    data
  })
}

/**
 * 开启核验码
 */
export const openCheckCode = (data) => {
  return request({
    url: '/manageHouse/OpenCheckCode',
    params: data
  })
}

/**
 * 获取个人名下在监管系统的房源接口
 */
export const getUserHouse = (data) => {
  return request({
    url: '/release/getOwnHouse',
    params: data
  })
}

/**
 * 查询个人已委托代理企业
 */
export const queryPersonAgentHouse = (data) => {
  return request({
    url: '/manageEnterprise/QueryPersonAgentHouse',
    params: data
  })
}

/**
 * 根据产权证获取房源信息
 */
export const getHouseInfo = (data) => {
  return request({
    url: '/release/houseInfo',
    method: 'POST',
    data
  })
}

/**
 * 人工核验接口
 */
export const houseCheck = (data) => {
  return request({
    url: '/release/houseCheck',
    method: 'POST',
    data
  })
}

/**
 * 个人核验接口
 */
export const getOwnHouseInfo = (data) => {
  return request({
    url: '/release/getOwnHouseInfo',
    method: 'POST',
    data
  })
}

/**
 * 在没有房源核验码情况下使用该接口开发二维码
 */
export const ownHouseCheck = (data) => {
  return request({
    url: '/release/ownHouseCheck',
    method: 'POST',
    data
  })
}

/**
 * 租赁交易服务-预约看房、网签接口
 */
export const postAppointSave = (data) => {
  return request({
    url: '/appoint/save',
    method: 'POST',
    data
  })
}

/**
 * 租赁交易服务-房源、网签预约列表接口
 */
export const postAppointList = (data) => {
  return request({
    url: '/appoint/list',
    method: 'POST',
    data
  })
}

/**
 * 租赁交易服务-预约者取消预约接口
 */
export const postAppointCancel = (data) => {
  return request({
    url: '/appoint/cancel',
    method: 'POST',
    data
  })
}

/**
 * 租赁交易服务-获取虚拟拨号电话号码
 */
export const postFreeVirtualPhone = (data) => {
  return request({
    url: '/free/virtualPhone',
    method: 'POST',
    data
  })
}

/**
 * 租赁交易服务-删除房源、网签预约接口
 */
export const postAppointDel = (data) => {
  return request({
    url: 'appoint/del',
    method: 'POST',
    data
  })
}

/**
 * 备案信息-获取相关的租赁备案信息
 */
export const postYwhistoryInfo = (data) => {
  return request({
    url: '/record/GetYwhistoryInfo',
    method: 'POST',
    data
  })
}

/**
 * 备案信息-申请注销历史备案
 */
export const postCancelYwhistory = (data) => {
  return request({
    url: '/record/CancelYwhistory',
    method: 'POST',
    data
  })
}

/**
 * 备案信息-历史备案注销确认
 */
export const postConfirmYwhistory = (data) => {
  return request({
    url: '/record/ConfirmYwhistory',
    method: 'POST',
    data
  })
}

/**
 * 上传
 */
export const uploadFile = (data) => {
  return request({
    url: '/file/upload',
    method: 'POST',
    data
  })
}

/**
 * 下载备案证明
 */
export const postRecordCertificate = (data) => {
  return request({
    url: '/record/certificate',
    method: 'POST',
    data
  })
}

/**
 * 新增房源拆分
 */
export const addSubleaseHouseApi = (data) => {
  return request({
    url: '/sulease/subleaseHouse',
    method: 'POST',
    data
  })
}

/**
 * 房源拆分变更
 */
export const changeSubleaseApi = (data) => {
  return request({
    url: '/sulease/subleaseChange',
    method: 'POST',
    data
  })
}
/**
 * 房源拆分变更
 */
export const getSplitStatusListApi = (data) => {
  return request({
    url: '/sulease/list',
    method: 'POST',
    data
  })
}

/**
 * 校验身份证正反面
 */
export const checkCardImg = (data) => {
  return request({
    url: '/esigne/checkCardImg',
    method: 'POST',
    data
  })
}

/**
 * 上传身份证
 */
export const uploadCardImg = (data) => {
  return request({
    url: '/esigne/uploadCardImg',
    method: 'POST',
    data
  })
}

/**
 * 撤销
 * @param businessId 业务ID
 * @returns {*}
 */
export const cancelSubleaseApply = (businessId) => {
  return request({
    url: '/sulease/cancelSubleaseApply',
    method: 'GET',
    params: { businessId }
  })
}

/**
 * 撤销
 * @param ywbh 业务编号
 * @returns {*}
 */
export const getSubleaseInfoApi = (ywbh) => {
  return request({
    url: '/sulease/GetSubleaseInfo',
    method: 'GET',
    params: { ywbh }
  })
}

/**
 *
 * @param 房东或者企业接受或拒绝预约接口
 * @returns {*}
 */
export const agreeAppoint = (data) => {
  return request({
    url: '/appoint/agree',
    method: 'POST',
    data
  })
}
