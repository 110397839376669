<template>
  <div style="height: 100%">
    <!--【版头】标题 -->
    <nesi-user-common :title="`备案信息`">
      <template #query>
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="业务编号" style="width: 293px">
            <el-input
              v-model="form.contractNo"
              clearable
              placeholder="请输入业务编号"
            />
          </el-form-item>
          <!--          <el-form-item label="备案合同状态">-->
          <!--            <el-select v-model="form.ywzt" clearable placeholder="请选择处理状态">-->
          <!---1: "待确认", 2: "确认中", 3: "网签备案失败", 4: "合同生效", 5: "待注销", 6: "注销中", 7: "注销失败",8: "合同失效",9,签约拒绝 10 解约拒绝 11 房源已网签12合同申请中 13 合同审核中 14审核不通过 15撤销申请失败 16 撤销合同申请 17传销租赁信息采集
              1申请 2审核中 3审核通过 4审核不通过
              -->
          <!--              <el-option label="待确认" value="1"/>-->
          <!--              <el-option label="确认中" value="2"/>-->
          <!--              <el-option label="网签备案失败" value="3"/>-->
          <!--              <el-option label="合同生效" value="4"/>-->
          <!--              <el-option label="待注销" value="5"/>-->
          <!--              <el-option label="注销中" value="6"/>-->
          <!--              <el-option label="注销失败" value="7"/>-->
          <!--              <el-option label="合同失效" value="8"/>-->
          <!--              <el-option label="签约拒绝" value="9"/>-->
          <!--              <el-option label="解约拒绝" value="10"/>-->
          <!--              <el-option label="房源已网签" value="11"/>-->
          <!--              <el-option label="合同申请中" value="12"/>-->
          <!--              <el-option label="合同审核中" value="13"/>-->
          <!--              <el-option label="审核不通过" value="14"/>-->
          <!--              <el-option label="撤销申请失败" value="15"/>-->
          <!--              <el-option label="撤销合同申请" value="16"/>-->
          <!--              <el-option label="传销租赁信息采集" value="17"/>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </template>
      <!--【主区】列表 -->
      <template #content>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa'
          }"
        >
          <el-table-column prop="ywlb" label="业务类别" width="120" />
          <el-table-column prop="htbh" label="合同编号" width="140" />
          <el-table-column prop="ywbh" label="业务编号" width="125" />
          <el-table-column prop="qlr" label="出租人姓名" width="140" />
          <el-table-column prop="czr" label="承租人姓名" width="140" />
          <el-table-column prop="ywzt" label="业务状态" width="80" />
          <el-table-column prop="fwzl" label="房屋坐落" />
          <el-table-column label="操作" width="120">
            <template #default="scope">
              <template v-if="scope.row.isEnterPrise == '1'">
                <span v-if="scope.row.ywzt == '合同生效'" style="color: red"
                  >企业业务，请到窗口办理注销业务！</span
                >
              </template>
              <template v-else>
                <el-button
                  type="text"
                  size="small"
                  class="list-btn"
                  style="color: red; margin-left: 0"
                  v-if="scope.row.status === 0"
                  @click="del(scope.row)"
                  >注销申请
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  class="list-btn"
                  style="color: red; margin-left: 0"
                  v-else-if="
                    scope.row.status &&
                    scope.row.status != 1 &&
                    scope.row.status != 2
                  "
                  @click="confirmation(scope.row)"
                  >注销确认
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  class="list-btn"
                  v-if="scope.row.ywzt !== '已注销'"
                  style="margin-left: 0"
                  @click="downs(scope.row)"
                  >证明下载
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="total, prev, pager, next"
          @current-change="currentChange"
          :total="total"
          :page-size="form.pageSize"
          style="text-align: right; margin: 10px"
        />
      </template>
    </nesi-user-common>
    <!--【弹窗】新增 -->
    <el-dialog
      title="新增咨询投诉"
      width="700px"
      v-model="addTableRowDialogVisible"
    >
      <el-form :inline="true" :model="addTableRowForm" class="demo-form-inline">
        <el-form-item label="类别" label-width="150px">
          <el-select
            v-model="addTableRowForm.type"
            clearable
            placeholder="请选择类别"
            style="width: 350px"
          >
            <el-option label="咨询" value="1" />
            <el-option label="投诉" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="内容描述" label-width="150px">
          <el-input
            v-model="addTableRowForm.complaintContents"
            clearable
            placeholder="请输入内容关键字"
            type="textarea"
            :rows="5"
            maxlength="255"
            style="width: 350px"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addTableRowDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addTableRow">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog
      v-model="checkDetailVisible"
      title="业务参与人信息"
      width="40%"
      top="20vh"
      :destroy-on-close="true"
      style="padding-top: 10px; position: relative"
    >
      <template #default>
        <div style="height: auto">
          <el-table
            border
            :data="checkDetailData"
            style="width: 100%"
            :cell-style="{ textAlign: 'center' }"
            :header-cell-style="{
              textAlign: 'center',
              backgroundColor: '#f5f7fa'
            }"
          >
            <el-table-column
              prop="ywcyrxm"
              label="业务参与人姓名"
              width="200"
            />
            <el-table-column prop="ywcyrid" label="业务参与人证件号" />
            <el-table-column prop="ywcyrlx" label="业务参与人类型" width="200">
            </el-table-column>
          </el-table>
        </div>
      </template>
    </el-dialog>
    <!--【弹窗】修改 -->
    <el-dialog
      title="扫描件上传(非必传)"
      width="900px"
      v-model="delApplyDialogVisible"
    >
      <el-upload
        v-model:file-list="fileList"
        class="upload-demo"
        action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
        :on-change="handleChange"
      >
        <el-button type="primary">上传扫描件</el-button>
        <template #tip>
          <div class="el-upload__tip">请上传用于注销合同的所需证明材料</div>
        </template>
      </el-upload>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="delApplyDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="editDelApplyTableRow"
            >提交</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {
  ElMessageBox,
  ElMessage,
  UploadProps,
  UploadUserFile
} from 'element-plus'
import { defineComponent, reactive, ref, onMounted } from 'vue'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import {
  postYwhistoryInfo,
  postCancelYwhistory,
  postConfirmYwhistory,
  uploadFile,
  postRecordCertificate
} from '@/api/user-house'
defineComponent({
  NesiUserCommon
})
export default {
  components: {
    NesiUserCommon
  },
  setup() {
    /**
     * 查询
     */
    const total = ref(0)
    const form = reactive({
      pageNo: 1,
      pageSize: 100,
      type: 1
    })
    const tableData = ref([])
    const findTableList = async () => {
      const resp = await postYwhistoryInfo(form)
      total.value = resp.length
      tableData.value = resp.map((item) => {
        const qlr = item.ywcyrs.filter((item) => item.ywcyrlx === '出租人') // 出租人
        const czr = item.ywcyrs.filter((item) => item.ywcyrlx === '承租人') // 承租人
        const qlrStr = qlr
          .reduce((pre, item) => {
            return pre + item.ywcyrxm + '，'
          }, '')
          .slice(0, -1)
        const czrStr = czr
          .reduce((pre, item) => {
            return pre + item.ywcyrxm + '，'
          }, '')
          .slice(0, -1)
        return {
          bazmbh: item.bazmbh,
          fwzl: item.fwzl,
          htbh: item.htbh,
          isEnterPrise: item.isEnterPrise,
          sfzx: item.sfzx,
          status: item.status,
          ywbh: item.ywbh,
          ywcyrs: item.ywcyrs,
          ywlb: item.ywlb,
          ywzt: displayStatus(item),
          zljssj: item.zljssj,
          zlkssj: item.zlkssj,
          qlr: qlrStr,
          czr: czrStr
        }
      })
    }

    const displayStatus = (value) => {
      let describe = ''
      if (value.ywzt == '审核通过') {
        switch (value.status) {
          case 1:
            describe = '注销确认中'
            break
          case 2:
            describe = '注销申请已经被拒绝'
            break
          default:
            describe = '合同生效'
            break
        }
      } else {
        describe = value.ywzt
      }
      return describe
    }
    const current = ref()
    const confirmation = (item) => {
      current.value = item
      ElMessageBox.confirm(
        '点击确认表示您同意注销此合同，点击拒绝表示您不同意注销此合同，请选择',
        '温馨提醒',
        {
          confirmButtonText: '确认',
          cancelButtonText: '拒绝',
          type: 'warning'
        }
      )
        .then(() => {
          handleCancel(2)
        })
        .catch(() => {
          handleCancel(1)
        })
    }

    const handleCancel = async (type) => {
      const params = {
        location: current.value.fwzl,
        bazmbh: current.value.bazmbh,
        type
      }
      const message = await postConfirmYwhistory(params)
      ElMessage({
        type: 'success',
        message
      })
      findTableList()
    }

    /**
     * 添加
     */
    const addTableRowDialogVisible = ref(false)
    const addTableRowForm = reactive({
      type: '',
      complaintContents: ''
    })
    const resetAddTableRowForm = () => {
      addTableRowForm.type = ''
      addTableRowForm.complaintContents = ''
    }
    const addTableRow = async (row) => {
      const addResp = await addUsercomplaint(addTableRowForm)
      console.log(addResp)
      addTableRowDialogVisible.value = false
      ElMessage({
        type: 'success',
        message: '添加成功'
      })
      resetAddTableRowForm()
      findTableList()
    }

    const checkDetailVisible = ref(false)
    const checkDetailData = ref([])
    const details = (row) => {
      checkDetailVisible.value = true
      checkDetailData.value = row.ywcyrs
    }

    /**
     *  上传扫描件
     */

    const fileList = ref < UploadUserFile > []
    const handleChange = async (uploadFiles, uploadFilesa) => {
      // const uploadResp = await uploadFile({file:formData})
    }

    const delApplyDialogVisible = ref(false)
    const zxridData = reactive({})
    const del = (row) => {
      zxridData.value = row
      delApplyDialogVisible.value = true
    }

    const editDelApplyTableRow = async (row) => {
      const cancelRes = await postCancelYwhistory({
        bazmbh: zxridData.value.bazmbh,
        fwzl: zxridData.value.fwzl,
        rentRecordHolders: zxridData.value.ywcyrs,
        smj: []
      })
      zxridData.value = reactive({})
      findTableList()
    }

    /**
     * 下载备案件
     */
    const downs = async (row) => {
      let type = row.isEnterPrise == 1 ? 2 : 1
      const downsRes = postRecordCertificate({
        type: type,
        certNo: row.bazmbh
      })
    }
    /**
     * 分页
     */
    const currentChange = (value) => {
      form.pageNo = value
      findTableList()
    }
    onMounted(() => {
      findTableList()
    })
    const onSubmit = async (row) => {
      if (
        (form.contractNo == null || form.contractNo == '') &&
        (form.ywzt === '' || form.ywzt == null)
      ) {
        findTableList()
        return
      }
      let tempArr = []
      tempArr.value = tableData.value.map((item) => {
        if (form.contractNo === item.ywbh) {
          tempArr.push({
            bazmbh: item.bazmbh,
            fwzl: item.fwzl,
            htbh: item.htbh,
            isEnterPrise: item.isEnterPrise,
            sfzx: item.sfzx,
            status: item.status,
            ywbh: item.ywbh,
            ywcyrs: item.ywcyrs,
            ywlb: item.ywlb,
            ywzt: item.ywzt,
            zljssj: item.zljssj,
            zlkssj: item.zlkssj
          })
        }
      })
      tableData.value = tempArr
    }
    return {
      form,
      tableData,
      addTableRowDialogVisible,
      delApplyDialogVisible,
      checkDetailVisible,
      checkDetailData,
      handleChange,
      UploadProps,
      onSubmit,
      del,
      downs,
      findTableList,
      details,
      editDelApplyTableRow,
      confirmation
    }
  }
}
</script>
<style>
.list-btn {
  border: #20a0ff;
  border-width: 1px;
}
</style>
