<template>
  <div class="user-common-wrapper">
    <div class="user-common-title-wrapper" v-if="title">
      <div class="user-common-title">{{ title }}</div>
      <div class="user-common-back" v-if="url">
        <el-button type="primary" plain size="small" @click="back">
          <el-icon>
            <ArrowLeft />
          </el-icon>
          返回
        </el-button>
      </div>
    </div>
    <div class="user-common-button" v-if="$slots.button">
      <slot name="button"></slot>
    </div>
    <div class="user-common-query" v-if="$slots.query">
      <slot name="query"></slot>
    </div>
    <div class="user-common-content" v-if="$slots.content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { ArrowLeft } from '@element-plus/icons-vue'

const props = defineProps({
  title: {
    type: String
  },
  url: String
})

const router = useRouter()
const back = () => {
  if (props.url === '-1') {
    router.back()
  } else {
    router.push(props.url)
  }
}
</script>

<style lang="scss" scoped>
.user-common-wrapper {
  background-color: #ffffff;
  height: 100%;
  // box-shadow: 0 0 10px #dcdfe6;
  // margin: 0 10px 0 10px;
  padding: 10px;

  .user-common-title-wrapper {
    height: 50px;
    line-height: 50px;
    border-bottom: solid 3px #a0cfff;
    display: flex;
    justify-content: space-between;

    .user-common-title {
      color: #409eff;
      border-bottom: solid 3px #409eff;
      position: relative;
      top: 2.5px;
    }
  }

  .user-common-button {
    padding: 10px 0;
  }

  .user-common-query {
    margin: 10px 0;
    border: solid 1px #ebeef5;
    padding: 22px 10px 0;
  }

  .user-common-content {
  }
}

::v-deep .el-button {
  span {
    display: flex;
    align-items: center;
    font-size: 13px;
  }
}
</style>
